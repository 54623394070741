<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200" v-if="corporate_data">
      <li v-for="user in corporate_data.users">
        <a href="#" class="block hover:bg-gray-50">
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p v-if="user.user.first_name" class="text-sm font-medium text-indigo-600 truncate">
                {{user.user.first_name}} {{user.user.last_name}}
              </p>
              <p v-else class="text-sm font-medium text-indigo-600 truncate">Invitation not yet accepted - {{user.user.email}}</p>
              <div class="ml-2 flex-shrink-0 flex">
                <!--                  <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">-->
                <!--                    22222-->
                <!--                  </p>-->
              </div>
            </div>
            <div class="mt-2 sm:flex sm:justify-between" v-for="course in user.courses">
              <div class="sm:flex">
                <p class="flex items-center text-sm text-gray-500">
                  <!--                    <UsersIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />-->
                  Course: {{ course.course }}
                </p>
                <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                  Tried to pass the test: <component :class="`flex-shrink-0 mr-1.5 h-5 w-5 ${course.tried ? 'text-green-500' : 'text-red-500'}`" :is="course.tried ? 'check-icon' : 'x-icon'" />
                </p>
                <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                  Passed the test: <component :class="`flex-shrink-0 mr-1.5 h-5 w-5 ${course.passed ? 'text-green-500' : 'text-red-500'}`" :is="course.passed ? 'check-icon' : 'x-icon'" />
                </p>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {UsersIcon, LocationMarkerIcon, CalendarIcon, CheckIcon, XIcon} from "@heroicons/vue/solid";

export default {
  name: 'AdminPanel',
  data(){
    return {
      corporate_data: []
    }
  },
  components: {
    UsersIcon,
    LocationMarkerIcon,
    CalendarIcon,
    CheckIcon,
    XIcon
  },
  async mounted() {
    let resp = await this.axios.get('user/corporate-preview/')
    this.corporate_data = resp.data
  }
}
</script>